<script>

export default {
    name: 'MixinsOrderMeta',
    data(){
        return {
            orderMeta: {
                all: {
                    status: 'all',
                    time_key: 'all_time',
                    text: this.$t('ทั้งหมด'),
                    desc: this.$t('รายการสั่งซื้อทั้งหมดของร้าน'),
                    color: 'primary',
                    text_color: 'green--text text--accent-4'
                },
                // wait_order: {
                //     status: 'wait_order',
                //     time_key: 'order_time',
                //     text: this.$t('รอยืนยันรายการ'),
                //     text_already: this.$t('คุณได้สร้างรายการสั่งซื้อแล้ว'),
                //     banner: this.$t('กรุณายืนยันรายการ'),
                //     desc: '',
                //     color: 'primary',
                //     text_color: 'white--text'
                // },
                wait_order_confirm: {
                    status: 'wait_order_confirm',
                    time_key: 'order_time',
                    text: this.$t('รอร้านยืนยันรายการ'),
                    text_already: this.$t('ร้านยืนยันรายการแล้ว'),
                    banner: this.$t('กรุณารอร้านค้ายืนยันรายการ'),
                    desc: this.$t('ร้านค้าได้รับข้อมูลการสั่งซื้อของคุณเรียบร้อยแล้ว และกำลังตรวจสอบรายการสินค้าพร้อมคิดราคาค่าจัดส่ง') + this.$t('กรุณารอดำเนินการ และกลับมาเช็คสถานะที่นี่ใหม่อีกครั้ง'),
                    color: 'teal darken-1',
                    text_color: 'teal--text text--darken-1'
                },
                wait_payment: {
                    status: 'wait_payment',
                    time_key: 'informpayment_time',
                    text: this.$t('รอชำระเงิน'),
                    text_already: this.$t('คุณได้แจ้งชำระเงินแล้ว'),
                    button_text: this.$t('ชำระเงิน'),
                    banner: this.$t('กรุณาชำระเงิน'),
                    desc: this.$t('กรุณาชำระเงิน') + this.$t('และแจ้งโอน (กรณีโอนเงินบัญชีธนาคาร)'),
                    color: 'green accent-4',
                    text_color: 'green--text text--accent-4'
                },
                wait_payment_verify: {
                    status: 'wait_payment_verify',
                    time_key: 'acceptpayment_time',
                    text: this.$t('รอร้านยืนยันยอด'),
                    text_already: this.$t('ร้านยืนยันยอดเงินแล้ว'),
                    button_text: this.$t('ระบุที่อยู่ผู้รับ'),
                    banner: this.$t('รอร้านค้ายืนยันยอดเงิน'),
                    desc: this.$t('ร้านค้าได้รับข้อมูลการแจ้งชำระเงินของคุณเรียบร้อยแล้ว และกำลังตรวจสอบยอดเงินที่แจ้งเพื่อยืนยันการสั่งซื้อของคุณ') + this.$t('กรุณารอดำเนินการ และกลับมาเช็คสถานะที่นี่ใหม่อีกครั้ง'),
                    color: 'orange darken-1',
                    text_color: 'orange--text text--darken-1'
                },
                wait_receiver_info: {
                    status: 'wait_receiver_info',
                    text: this.$t('รอระบุที่อยู่ผู้รับ'),
                    text_already: this.$t('ระบุที่อยู่ผู้รับแล้ว'), //น่าจะไม่ได้แสดงที่ไหน
                    button_text: this.$t('ระบุที่อยู่'),
                    banner: this.$t('กรุณาระบุที่อยู่ผู้รับสินค้า'),
                    desc: this.$t('กรุณาระบุที่อยู่ผู้รับสินค้าของคุณ เพื่อให้ร้านจัดส่งสินค้าได้อย่างถูกต้อง'),
                    color: 'red darken-1',
                    text_color: 'red--text text--darken-1'
                },
                wait_send: {
                    status: 'wait_send',
                    time_key: 'send_time',
                    text: this.$t('รอจัดส่ง'),
                    text_already: this.$t('ร้านจัดส่งสินค้าแล้ว'),
                    button_text: this.$t('ดูระยะเวลาส่ง'),
                    banner: this.$t('รอร้านค้าแพ็คและจัดส่งสินค้า'),
                    desc: this.$t('ร้านค้ายืนยันการสั่งซื้อเรียบร้อยแล้ว และอยู่ในระหว่างการแพ็คสินค้าเพื่อจัดส่งไปยังผู้ให้บริการขนส่ง กรุณากลับเข้ามาอัพเดทสถานะใหม่อีกครั้ง'),
                    color: 'amber darken-1',
                    text_color: 'amber--text text--darken-1'
                },
                sent: {
                    status: 'sent',
                    text: this.$t('รอรับสินค้า'),
                    text_already: this.$t('รอรับสินค้า'),
                    button_text: this.$t('ดูรหัสพัสดุ'),
                    banner: this.$t('ร้านค้าจัดส่งสินค้าแล้ว'),
                    desc: this.$t('ร้านค้าได้จัดส่งสินค้าไปยังผู้ขนส่งเรียบร้อยแล้ว กรุณารอรับสินค้า ขอบคุณและหวังว่าจะได้รับคำสั่งซื้อจากคุณอีกครั้ง'),
                    color: 'light-blue accent-3',
                    text_color: 'light-blue--text text--accent-3',
                    last: true,
                },
                wait_receive: {
                    status: 'wait_receive',
                    time_key: 'receive_time',
                    text: this.$t('รอรับสินค้า'),
                    text_already: this.$t('พัสดุถึงผู้รับเรียบร้อยแล้ว'),
                    button_text: this.$t('ติดตามพัสดุ'),
                    banner: this.$t('จัดส่งแล้ว กรุณายืนยันหากได้รับสินค้าแล้ว'),
                    desc: this.$t('ร้านค้าได้จัดส่งพัสดุแล้ว กรุณารอรับสินค้าจากผู้ให้บริการขนส่ง คุณสามารถตรวจสอบและติตตามพัสดุได้ที่ข้อมูลการจัดส่งด้านล่างนี้'),
                    color: 'light-blue accent-3',
                    text_color: 'light-blue--text text--accent-3',
                    only_lnwpay: true,
                },
                wait_confirm: {
                    status: 'wait_confirm',
                    time_key: 'confirm_time',
                    text: this.$t('รอยืนยันรับสินค้า'),
                    text_already: this.$t('คุณยืนยันรับสินค้าแล้ว'),
                    button_text: this.$t('ยืนยันรับสินค้า'),
                    banner: this.$t('สินค้าถึงผู้รับแล้ว กรุณายืนยันหากได้รับสินค้าแล้ว'),
                    desc: this.$t('ระบบตรวจสอบจากรหัสพัสดุ พบว่าพัสดุได้ส่งถึงมือผู้รับแล้ว กรุณายืนยันหากคุณได้รับสินค้าแล้ว') + ' ' + this.$t('หรือหากมีปัญหาในการรับพัสดุ กรุณาแจ้งข้อร้องเรียน เพื่อให้ทีมงานตรวจสอบอีกครั้ง'),
                    color: 'light-blue accent-3',
                    text_color: 'light-blue--text text--accent-3',
                    only_lnwpay: true,
                },
                completed: {
                    status: 'completed',
                    text: this.$t('เสร็จเรียบร้อย'),
                    text_already: '',
                    button_text: this.$t('สั่งซื้อสินค้าใหม่'),
                    banner: this.$t('การสั่งซื้อเสร็จเรียบร้อยแล้ว') + ' Thank you.',
                    desc: this.$t('รายการสั่งซื้อนี้เสร็จสิ้นแล้ว คุณได้รับสินค้าครบถ้วน และร้านค้าได้รับเงินเป็นที่เรียบร้อยแล้ว ขอบคุณและหวังว่าจะได้รับคำสั่งซื้อจากคุณอีกครั้ง'),
                    color: 'blue darken-2',
                    text_color: 'blue--text text--darken-2',
                    last: true,
                    only_lnwpay: true,
                },
                expired: {
                    status: 'expired',
                    text: this.$t('หมดอายุ'),
                    text_already: this.$t('หมดอายุ'),
                    button_text: this.$t('สั่งซื้อสินค้าใหม่'),
                    banner: this.$t('รายการสั่งซื้อนี้หมดอายุแล้ว'),
                    desc: this.$t('คุณทำรายการสั่งซื้อไม่เสร็จสิ้นก่อนวันที่กำหนด กรุณาสั่งซื้อสินค้าใหม่อีกครั้ง'),
                    color: 'grey darken-1',
                    text_color: 'grey--text text--darken-1',
                    last: true,
                },
                refund: {
                    status: 'refund',
                    text: this.$t('คืนเงิน'),
                    text_already: this.$t('คืนเงิน'),
                    button_text: this.$t('สั่งซื้อสินค้าใหม่'),
                    banner: this.$t('รายการสั่งซื้อนี้คืนเงิน'),
                    desc: this.$t('ร้านค้าคืนเงินแล้ว'),
                    color: 'grey darken-1',
                    text_color: 'grey--text text--darken-1',
                    last: true,
                },
                buyer_cancelled: {
                    status: 'buyer_cancelled',
                    text: this.$t('ยกเลิก'),
                    text_already: this.$t('ยกเลิก'),
                    button_text: this.$t('สั่งซื้อสินค้าใหม่'),
                    banner: this.$t('ยกเลิกรายการสั่งซื้อนี้แล้ว'),
                    desc: this.$t('คุณได้ยกเลิกรายการสั่งซื้อนี้แล้ว'),
                    color: 'grey darken-1',
                    text_color: 'grey--text text--darken-1',
                    last: true,
                },
                seller_cancelled: {
                    status: 'seller_cancelled',
                    text: this.$t('ถูกยกเลิก'),
                    text_already: this.$t('ถูกยกเลิก'),
                    button_text: this.$t('สั่งซื้อสินค้าใหม่'),
                    banner: this.$t('รายการสั่งซื้อนี้ถูกยกเลิก'),
                    desc: this.$t('ร้านค้าได้ยกเลิกรายการสั่งซื้อนี้แล้ว'),
                    color: 'grey darken-1',
                    text_color: 'grey--text text--darken-1',
                    last: true,
                },
                suspended: {
                    status: 'suspended',
                    text: this.$t('ร้องเรียน'),
                    text_already: '',
                    banner: this.$t('รายการสั่งซื้อนี้ถูกระงับ'),
                    desc: this.$t('คุณร้องเรียนรายการสั่งซื้อนี้'),
                    color: 'red darken-1',
                    text_color: 'red--text text--darken-1',
                    last: true,
                },
            }
        }
    },
    computed: {
        allOrderStatus() {
            return Object.keys(this.orderMeta);
        }
    },
    watch: {}
}
</script>