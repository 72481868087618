<template>
    <LnwmeLayout v-bind="{ myOrderDrawer, skipLogin : true}">
        <template #toolbar>
            <v-app-bar-nav-icon>
                <template v-if="isDesktop">
                    <v-icon @click.stop="myOrderDrawer = !myOrderDrawer">
                        {{ myOrderDrawer ? 'mdi-menu-open' : 'mdi-storefront-outline' }}
                    </v-icon>
                </template>
                <template v-else>
                    <v-icon @click.stop="myOrderDrawer = !myOrderDrawer">
                        mdi-menu
                    </v-icon>
                </template>
            </v-app-bar-nav-icon>
            <v-toolbar-title>
                {{ $t('แจ้งโอนเงิน (ส่งสลิป)') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </template>
        <template #drawer>
            <v-navigation-drawer v-if="shopData"
                                 v-model="myOrderDrawer"
                                 app
                                 left
                                 :permanent="isDesktop"
                                 :width="myOrderDrawer?320:0"
                                 style="height: 100%; overflow: auto; z-index: 1021; box-sizing: border-box;">
                <v-list class="pt-4 px-4 pb-1 sticky-top">
                    <v-list-item two-line
                                 link
                                 dense
                                 :href="shopData.url"
                                 target="_blank"
                                 class="text-decoration-none elevation-24 border bg-blurry">
                        <v-list-item-action>
                            <v-img width="40"
                                   class="rounded-lg"
                                   :src="shopData.avatar"></v-img>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title><b>{{ shopData.name }}</b></v-list-item-title>
                            <v-list-item-subtitle style="font-size: 0.8em;">
                                {{ shopData.domain }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon color="primary"
                                    small>
                                mdi-open-in-new
                            </v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <!--                <v-divider></v-divider>-->
                <v-list dense
                        subheader>
                    <v-subheader style="margin: 5px 5px 0px;">
                        {{ $t('รายการสั่งซื้อของฉัน') }}
                    </v-subheader>
                    <div v-for="(status, i) in tabItems"
                         :key="i">
                        <v-list-item link
                                     :class="(('tab-' + status) == currentStatus)?'blue-grey lighten-5':''"
                                     @click="$router.push('/order/' + shopData.web_name + '?status=' + status)">
                            <v-list-item-icon style="text-align: center">
                                <div class="white--text app-like-small"
                                     :class="orderMeta[status].color">
                                    <template v-if="status != 'all'">
                                        <img :src="require('@/assets/order_status/' + status + '.png')"
                                             style="margin: 0 auto; filter: grayscale(100%) brightness(100)"
                                             width="14"/>
                                    </template>
                                    <template v-else>
                                        <v-icon color="white"
                                                size="14">mdi-shopping
                                        </v-icon>
                                    </template>
                                </div>
                            </v-list-item-icon>
                            <v-list-item-title style="line-height: 1.75"
                                               :class="(('tab-' + status) == currentStatus)?'font-weight-bold':''">
                                {{ orderMeta[status].text }}
                                <v-tooltip v-if="orderMeta[status].only_lnwpay"
                                           top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs"
                                                x-small
                                                color="(('tab-' + status) == currentStatus)?'white':'primary'"
                                                v-on="on">mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ $t('เฉพาะรายการสั่งซื้อที่มีการชำระเงินผ่าน LnwPay ซึ่งจะได้รับความคุ้มครองผู้ซื้อ กรณีไม่ได้รับสินค้า') }}</span>
                                </v-tooltip>
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                    <v-list-group v-if="moreTabItems && moreTabItems.length"
                                  :value="false">
                        <template v-slot:activator>
                            <v-list-item-icon style="text-align: center">
                                <div class="white--text app-like-small">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </div>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="">{{ $t('เพิ่มเติม') }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item style="padding-left: 20px"
                                     v-for="(status, i) in moreTabItems"
                                     @click="$router.push('/order/' + shopData.web_name + '?status=' + status)"
                                     :key="i"
                                     :class="(('tab-' + status) == currentStatus)?'blue-grey lighten-5':''"
                                     link>
                            <v-list-item-icon style="text-align: center">
                                <div class="white--text app-like-small"
                                     :class="orderMeta[status].color">
                                    <img :src="require('@/assets/order_status/' + status + '.png')"
                                         style="margin: 0 auto; filter: grayscale(100%) brightness(100)"
                                         width="14"/>
                                </div>
                            </v-list-item-icon>
                            <v-list-item-title style="line-height: 1.75"
                                               :class="(('tab-' + status) == currentStatus)?'font-weight-bold':''">
                                {{ orderMeta[status].text }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list>
                <template v-slot:append>
                    <v-list class="px-4" v-if="!isDesktop">
                        <v-btn block
                               large
                               @click="myOrderDrawer = false"
                               class="my-4">
                            {{ $t('ปิดหน้าต่าง') }}
                        </v-btn>
                    </v-list>
                </template>
            </v-navigation-drawer>
        </template>
        <template #default>
            <inform-payment-dialog :directInform="true" component="card" guest="true"></inform-payment-dialog>
        </template>
    </LnwmeLayout>
</template>
<script>
import {mapGetters, mapState, mapActions} from "vuex";
import LnwmeLayout from "@/views/layouts/LnwmeLayout.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import MixinsOrderMeta from "@/mixins/MixinsOrderMeta";
import {clock} from "@/libraries/DateTime.js";
import Helper from "@/libraries/Helper";
import InformPaymentDialog from "@/components/Order/components/PaymentMethod/InformPaymentDialog";
import {SET_INFORM_DIALOG} from "@/store/modules/order/mutation-types";
import {SET_IS_AJAX_RESPONSE, SET_IS_AJAX_RESPONSE_MESSAGE} from "@/store/modules/order/mutation-types";

export default {
    name: "MyOrders",
    components: {
        LnwmeLayout,
        InformPaymentDialog,
    },
    mixins: [
        MixinsHelper,
        MixinsOrderMeta
    ],
    data(){
        return {
            currentStatus: 'tab-all',
            moreStatus: ['expired', 'refund', 'buyer_cancelled', 'seller_cancelled', 'suspended'],
            myOrderDrawer: false,
        }
    },
    computed: {
        ...mapState({
            userData: state => state.user.userData,
            shopData: state => state.myOrders.shopData,
            userDataInShop: state => state.myOrders.userDataInShop,
            // object lnwpoint จาก API lnwpay
            isAjaxLoading: state => state.order.isAjaxLoading,
        }),
        ...mapGetters({
            isLogin: "user/isLogin",
        }),
        informDialog: {
            get(){
                return this.$store.state.order.informDialog;
            },
            set(value){
                this.$store.commit('order/' + SET_INFORM_DIALOG, value);
            },
        },
        isAjaxResponse: {
            get(){
                return this.$store.state.order.isAjaxResponse;
            },
            set(value){
                this.$store.commit('order/' + SET_IS_AJAX_RESPONSE, value);
            },
        },
        isAjaxResponseMessage: {
            get(){
                return this.$store.state.order.isAjaxResponseMessage;
            },
            set(value){
                this.$store.commit('order/' + SET_IS_AJAX_RESPONSE_MESSAGE, value);
            },
        },
        isCopied: {
            get(){
                return this.$store.state.order.isCopied;
            },
            set(value){
                this.$store.dispatch('order/setIsCopied', value);
            },
        },
        tabItems(){
            if(!this.orderMeta) return [];
            let that = this;
            return this.allOrderStatus.filter((status) => !that.moreStatus.includes(status))
        },
        moreTabItems(){
            if(!this.orderMeta) return [];
            let that = this;
            return this.allOrderStatus.filter((status) => that.moreStatus.includes(status))
        },
        clock(){
            return clock;
        },
        shouldRedirect() {
            return this.isLogin && this.shopData && this.shopData.web_name;
        },
    },

    methods: {
        ...mapActions({

        }),
        number_format(num, decimal){
            return Helper.number_format(num, decimal);
        },
    },
    mounted(){
    },
    watch: {
        shouldRedirect(newValue) {
            if(newValue){
                this.$router.push('/order/' + this.shopData.web_name + '?inform=1');
            }
        }
    }
};
</script>
<style scoped></style>